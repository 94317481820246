import * as React from "react";
import { logger } from "bernie-core";

import { withLocalization } from "@shared-ui/localization-context";

import { inject, observer } from "mobx-react";

import {
  StorefrontWizardRegionTypesContext,
  StorefrontWizardRegionTypes,
} from "components/flexComponents/PageLevelProviders/WizardContextProvider/StorefrontWizardRegionTypesContext";
import {
  getWizardImageOverride,
  getWizardLOBTemplateComponents,
  getAboveNonWizardTemplateComponents,
  getBelowNonWizardTemplateComponents,
  getStandaloneLOBModulesInPackages,
} from "components/shared/StorefrontWizard/StorefrontWizardUtils";

import { getTracker } from "utils/TrackingUtils";
import {
  StorefrontWizardRegionBEXProps,
  StorefrontWizardRegionBEXTemplateComponentConfig,
  LobNameMap,
} from "./typings";

import { StorefrontWizardRegionBEXViewV2 } from "./components/StorefrontWizardRegionBEXViewV2";
import { WizardBackgroundImageOverrideProps } from "../typings";
import { PackagesStandaloneWizardModulesContext } from "../../PageLevelProviders/WizardContextProvider/PackagesStandaloneWizardModulesContext";

@withLocalization
@logger("StorefrontWizardRegionBEX")
@observer
@inject("wizardState", "analytics", "context", "flexModuleModelStore", "compositionStore")
export class StorefrontWizardRegionBEX extends React.Component<StorefrontWizardRegionBEXProps> {
  public render() {
    const { context, wizardState, analytics, blossomComponent, templateComponent, compositionStore } = this.props;

    if (!blossomComponent || !templateComponent.children || templateComponent.children.length < 1) {
      return null;
    }

    //default to false if device information is not available
    wizardState.globalWizardState.setIsDesktop(context.deviceInformation);

    const options = templateComponent.config as StorefrontWizardRegionBEXTemplateComponentConfig;
    if (options.defaultLOB) {
      wizardState.globalWizardState.config.navigation.defaultLob = options.defaultLOB;
      wizardState.globalWizardState.config.navigation.activeLob = options.defaultLOB;
    }

    const backgroundImageOverride: WizardBackgroundImageOverrideProps | undefined = getWizardImageOverride(
      templateComponent.children,
      context
    );

    const wizardFormChildren = getWizardLOBTemplateComponents(templateComponent.children);

    const aboveOtherFormChildren = getAboveNonWizardTemplateComponents(templateComponent.children);

    const belowOtherFormChildren = getBelowNonWizardTemplateComponents(templateComponent.children);

    wizardState.globalWizardState.config.heroImageSrc =
      backgroundImageOverride?.src ?? options.backgroundImage ?? wizardState.globalWizardState.config.heroImageSrc;
    const { heroImageSrc } = wizardState.globalWizardState.config;

    const heroImageColor = backgroundImageOverride?.color ?? options.backgroundImageColor;

    const lobNameMap: LobNameMap = {
      "wizard-hotel-pwa-v2": "_Hotels",
      "wizard-flight-pwa": "_Flights",
      "wizard-package-pwa": "_Packages",
      "wizard-car-pwa": "_Cars",
      "wizard-lx-pwa": "_Lx",
      "wizard-cruise-pwa": "_Cruise",
      "wizard-thirdPartyPackage-pwa": "_ThirdPartyPackage",
      "wizard-externalLinkTab-pwa": "_ExternalLinkTab",
    };

    const trackOnChangeTab = (tabName: string, rfrrId?: string) => {
      const moduleName = templateComponent.type || "SFWizardBEX";
      const tracker = getTracker(wizardState.canTrack, analytics, moduleName);
      const lobName = lobNameMap[tabName as keyof LobNameMap];
      const rfrr = `LOB_Selection_Occured${lobName ?? ""}`;
      tracker.trackEvent(`${rfrr}${rfrrId ?? ""}`);
    };

    const {
      title,
      cdgTreatment,
      titleFromPageHeader,
      subTitleFromPageSubHeader,
      innerTitle,
      cobrandImageUrl,
    } = options;

    const shouldDisplayPackageWizardMultiLOB = wizardState.globalWizardState.config.includeSingleLOBsWithinPackages;
    const wizardModules = shouldDisplayPackageWizardMultiLOB
      ? getStandaloneLOBModulesInPackages(templateComponent.children)
      : [];

    wizardState.globalWizardState.config.cobrandImageUrl = cobrandImageUrl;

    const heading = titleFromPageHeader ? compositionStore.composition!.pageHeading : title;
    const subHeading = subTitleFromPageSubHeader ? compositionStore.composition!.pageSubHeadline : innerTitle;

    const wizardViewProps = {
      heroImageSrc,
      context,
      trackOnChangeTab,
      wizardFormChildren,
      wizardState,
      heroImageColor,
      cdgTreatment,
      heading,
      subHeading,
      blossomComponent,
    };

    /**
     *
     * Note for modules below this region:
     * Modules not part of this region do not align to the form content out of the box
     * Setting the flex manager attribute brandModulePadding to bex_padding puts a spacing__twelve on the components below this region
     * This aligns them with the form content within the card on this wizard
     * On mobile, there is no card, and this same attribute puts spacing__six on these components
     */
    return (
      <StorefrontWizardRegionTypesContext.Provider value={StorefrontWizardRegionTypes.STOREFRONT_WIZARD_REGION_BEX}>
        <PackagesStandaloneWizardModulesContext.Provider
          value={{ templateComponents: wizardModules, blossomComponent: blossomComponent }}
        >
          <StorefrontWizardRegionBEXViewV2
            {...wizardViewProps}
            aboveOtherFormChildren={aboveOtherFormChildren}
            belowOtherFormChildren={belowOtherFormChildren}
          />
        </PackagesStandaloneWizardModulesContext.Provider>
      </StorefrontWizardRegionTypesContext.Provider>
    );
  }
}

export default StorefrontWizardRegionBEX;
