import * as React from "react";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export interface HeroImageProps {
  src: string;
  className?: string;
}

export const HeroImage: React.FC<HeroImageProps> = ({ src, children, className = "" }) => (
  <UitkLayoutFlex className="bexHeroImageBackground">
    <UitkLayoutFlexItem>
      <UitkFigure ratio={UitkFigureAspectRatioType.R4_1} className={className} src={src}>
        {children}
      </UitkFigure>
    </UitkLayoutFlexItem>
  </UitkLayoutFlex>
);
