import * as React from "react";

import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { WizardTabbed } from "components/shared/StorefrontWizard/WizardTabbed/WizardTabbed";
import { WizardHero } from "components/shared/StorefrontWizard/WizardHero/WizardHero";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { UitkSpacingProps, UitkSpacing } from "@egds/react-core/spacing";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from "@shared-ui/experiment-context";

export const StorefrontWizardRegionBEXViewV2 = (props: {
  heroImageSrc: string;
  heroImageColor: string;
  context: any;
  trackOnChangeTab: (tab: string) => void;
  wizardState: ClientWizardStateStore;
  cdgTreatment?: boolean;
  wizardFormChildren: ExtendedTemplateComponent[];
  aboveOtherFormChildren: ExtendedTemplateComponent[];
  belowOtherFormChildren: ExtendedTemplateComponent[];
  heading?: string;
  subHeading?: string;
  blossomComponent: BlossomComponent;
}) => {
  const {
    context,
    wizardFormChildren,
    trackOnChangeTab,
    belowOtherFormChildren,
    aboveOtherFormChildren,
    cdgTreatment,
    blossomComponent,
    heroImageColor,
  } = props;
  const className = "StorefrontWizardRegionBEX";
  const { exposure } = useExperiment("Search_wizard_only_above_the_fold");
  const backgroundImageCovered = exposure.bucket === 1 ? "regionImageBackground" : "";

  const regionSpacing: UitkSpacingProps = { padding: { blockend: "unset" }, margin: { blockend: "unset" } };
  const wizardHero = (
    <WizardHero heroImageFigureClassNames={`bexHeroImageFigureV2 ${backgroundImageCovered}`} {...props} />
  );
  const isSingleLOB = wizardFormChildren?.length === 1;
  const customStyle = heroImageColor ? { backgroundColor: heroImageColor } : undefined;
  const wizardRender = cdgTreatment ? (
    wizardHero
  ) : (
    <Viewport>
      <ViewSmall>
        {isSingleLOB ? (
          <Experiment name="Search_wizard_only_above_the_fold">
            <ExperimentControl>
              <UitkSpacing padding={{ inline: "six" }}>
                <div data-testid="single-lob">
                  <RegionChildrenList templateComponents={wizardFormChildren} blossomComponent={blossomComponent} />
                </div>
              </UitkSpacing>
            </ExperimentControl>
            <ExperimentVariant bucket={2}>
              <UitkSpacing padding="six">
                <div style={customStyle}>{wizardHero}</div>
              </UitkSpacing>
            </ExperimentVariant>
          </Experiment>
        ) : (
          <WizardTabbed
            context={context}
            wizardFormChildren={wizardFormChildren}
            trackOnChangeTab={trackOnChangeTab}
            belowOtherFormChildren={belowOtherFormChildren}
            aboveOtherFormChildren={aboveOtherFormChildren}
            blossomComponent={blossomComponent}
          />
        )}
      </ViewSmall>
      <ViewMedium>{wizardHero}</ViewMedium>
    </Viewport>
  );

  return (
    <UitkSpacing {...regionSpacing}>
      <div className={`${className}`} data-testid="storefront-region">
        <div id="wizardMainRegionV2" data-testid="main-region">
          {wizardRender}
        </div>
      </div>
    </UitkSpacing>
  );
};
