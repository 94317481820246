import * as React from "react";

import { LobFormWithTabs } from "components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { LobQueryRouter } from "components/shared/StorefrontWizard/LobQueryRouter";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { WizardComponentTemplateComponentConfig } from "../typings";

interface TabbedWizardProps {
  context: any;
  wizardFormChildren: ExtendedTemplateComponent[];
  trackOnChangeTab?: (tab: string) => void;
  aboveOtherFormChildren?: ExtendedTemplateComponent[];
  belowOtherFormChildren?: ExtendedTemplateComponent[];
  blossomComponent: BlossomComponent;
}

export const WizardTabbed = ({
  context,
  wizardFormChildren,
  trackOnChangeTab,
  aboveOtherFormChildren,
  belowOtherFormChildren,
  blossomComponent,
}: TabbedWizardProps) => {
  const forms = (
    <LobFormWithTabs
      context={context}
      templateComponents={wizardFormChildren}
      wizardFormSpacing={{ padding: { inline: "six" } }}
      trackOnChangeTab={trackOnChangeTab}
      blossomComponent={blossomComponent}
    />
  );

  return (
    <>
      <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />

      {aboveOtherFormChildren &&
        aboveOtherFormChildren.map((child) => (
          <div
            key={`parent-${child?.metadata.id}`}
            className={
              child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                ? "prevent-justify-center"
                : ""
            }
          >
            <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
          </div>
        ))}

      {forms}

      {belowOtherFormChildren &&
        belowOtherFormChildren.map((child) => (
          <div
            key={`parent-${child?.metadata.id}`}
            className={
              child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                ? "prevent-justify-center"
                : ""
            }
          >
            <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
          </div>
        ))}
    </>
  );
};
