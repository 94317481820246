import * as React from "react";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";

import { HeroImage } from "components/shared/StorefrontWizard/HeroImage/HeroImage";
import {
  TabbedWizardCard,
  WizardRegionType,
} from "components/shared/StorefrontWizard/TabbedWizardCard/TabbedWizardCard";
import { LobQueryRouter } from "components/shared/StorefrontWizard/LobQueryRouter";
import { useMediaQueries, defaultViewSizes, Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { WizardComponentTemplateComponentConfig } from "../typings";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { useExperiment } from "@shared-ui/experiment-context";

export interface WizardHeroProps {
  wizardFormChildren: ExtendedTemplateComponent[];
  trackOnChangeTab: (tabName: string, rfrrId?: string) => void;
  context: ExtendedContextStore;
  heroImageFigureClassNames?: string;
  heroImageSrc: string;
  heroImageColor?: string;
  wizardState: ClientWizardStateStore;
  cdgTreatment?: boolean;
  aboveOtherFormChildren?: ExtendedTemplateComponent[];
  belowOtherFormChildren?: ExtendedTemplateComponent[];
  heading?: string;
  subHeading?: string;
  blossomComponent: BlossomComponent;
}

export const WizardHero: React.FC<WizardHeroProps> = ({
  wizardFormChildren,
  trackOnChangeTab,
  context,
  heroImageFigureClassNames,
  heroImageSrc,
  heroImageColor,
  wizardState,
  aboveOtherFormChildren,
  belowOtherFormChildren,
  cdgTreatment,
  heading,
  subHeading,
  blossomComponent,
}) => {
  const customStyle = heroImageColor ? { backgroundColor: heroImageColor } : undefined;
  const { exposure } = useExperiment("Search_wizard_only_above_the_fold");
  const viewportSize = useMediaQueries({ viewSizes: defaultViewSizes });
  const paddingTopValue = () => {
    if (exposure.bucket === 1) {
      if (viewportSize === defaultViewSizes.MEDIUM) {
        return "six";
      }
      return "eight";
    }
    return "twelve";
  };

  const wizardHeroContent = (
    <>
      {cdgTreatment && <UitkScrim type={UitkScrimType.OVERLAY} />}
      {heading && (
        <UitkSpacing
          className="SimpleContainer cdgHeading"
          padding={{ inline: "six", blockstart: "six", small: { blockend: "six" }, medium: { blockend: "unset" } }}
        >
          <div>
            <Viewport>
              <ViewSmall>
                <UitkHeading className="cdgHeadingText" tag="h1" size={cdgTreatment ? 4 : 2}>
                  {heading}
                </UitkHeading>
              </ViewSmall>
              <ViewMedium>
                <UitkHeading className="cdgHeadingText" tag="h1" size={2}>
                  {heading}
                </UitkHeading>
              </ViewMedium>
            </Viewport>
            {subHeading && (
              <UitkSubheading className="cdgHeadingText" tag="h2">
                {subHeading}
              </UitkSubheading>
            )}
          </div>
        </UitkSpacing>
      )}

      {aboveOtherFormChildren &&
        aboveOtherFormChildren.map((child) => (
          <div
            key={`parent-${child?.metadata.id}`}
            className={
              child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                ? "prevent-justify-center"
                : ""
            }
          >
            <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
          </div>
        ))}
      <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
      <TabbedWizardCard
        context={context}
        wizardFormChildren={wizardFormChildren}
        wizardState={wizardState}
        wrappingClassNames="SimpleContainer"
        wrappingSpacing={{
          padding: { medium: { inline: "six", blockstart: heading ? "six" : paddingTopValue(), blockend: "four" } },
        }}
        tabGroupClassNames="formAlignedTabs"
        wizardFormClassNames="wizardOverHeroImage"
        wizardFormSpacing={{ padding: { inline: "six" } }}
        trackOnChangeTab={trackOnChangeTab}
        wizardRegionType={WizardRegionType.BEX}
        hasBorder={!heroImageSrc}
        blossomComponent={blossomComponent}
      />
      {belowOtherFormChildren &&
        belowOtherFormChildren.map((child) => (
          <div
            key={`parent-${child?.metadata.id}`}
            className={
              child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                ? "prevent-justify-center"
                : ""
            }
          >
            <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
          </div>
        ))}
    </>
  );

  return (
    <div style={customStyle}>
      {heroImageSrc ? (
        <HeroImage className={heroImageFigureClassNames} src={heroImageSrc}>
          {wizardHeroContent}
        </HeroImage>
      ) : (
        wizardHeroContent
      )}
    </div>
  );
};
